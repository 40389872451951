import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllRequestedWallet = async (
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/wallet/wallet-withdrawal/requested?page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAcceptWallet = async (id) => {
  try {
    return await getData(`/wallet/wallet-withdrawal/accepted-requested/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const getRejectedWallet = async (id) => {
  try {
    return await getData(`/wallet/wallet-withdrawal/rejected-requested/${id}`);
  } catch (error) {
    console.error(error);
  }
};
