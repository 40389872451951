import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Admin/Login/Login";

import Dashboard from "./Components/Admin/DashboardMain/DashboardMain/Tables";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import AdvanceDashboard from "./Components/Admin/DashBoard/AdvanceDashboard/AdvanceDashboard";
import PrivateDashboard from "./Components/Admin/DashBoard/PrivateDashboard/PrivateDashboard";
import Order from "./Components/Admin/DashBoard/Order/Order";

// Masters

// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";

// Talent Pool

// Recruiters

import EmployeeRole from "./Components/Admin/EmployeeManagement/Role/Tables";

import EmployeeDetails from "./Components/Admin/EmployeeManagement/EmployeeDetails/Tables";

import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Pincode from "./Components/Admin/Masters/Pincode/Tables";
import Category from "./Components/Admin/Masters/Category/Table";
import FoodType from "./Components/Admin/Masters/FoodType/Table";
import HomeBanner from "./Components/Admin/Masters/HomeBanner/Table";
import TiffinBanner from "./Components/Admin/Masters/TiffinBanner/Table";

import Order_cancel_reason from "./Components/Admin/Masters/Order_cancel_reason/Tables";
import Subscription_cancel_reason from "./Components/Admin/Masters/Subscription_cancel_reason/Tables";
import Undelivered_reason from "./Components/Admin/Masters/Undelivered_reason/Tables";
import Delivery_instructions from "./Components/Admin/Masters/Delivery_instructions/Table";
import Cancellation_policy from "./Components/Admin/Masters/Cancellation_policy/Table";
import CouponCodes from "./Components/Admin/Masters/CouponCodes/Table";

import Restaurent from "./Components/Admin/Masters/Restaurent/Table";

// dishes
import Dishes from "./Components/Admin/Dishes/Dishes/Table";
import AdminDishes from "./Components/Admin/Dishes/AdminDishes/Table";
import RecommendedDishes from "./Components/Admin/Dishes/RecommendedDishes/Table";

// orders
import Orders from "./Components/Admin/Orders/Orders/Orders";
import ReturnOrders from "./Components/Admin/Orders/ReturnOrders/Orders";
import CancelOrders from "./Components/Admin/Orders/CancelOrders/Orders";
import RefundOrders from "./Components/Admin/Orders/RefundOrders/Orders";

// Wallet
import ActiveWallet from "./Components/Admin/Wallet/ActiveWallet/Orders";
import AcceptedWallet from "./Components/Admin/Wallet/AcceptedWallet/Orders";
import RejectedWallet from "./Components/Admin/Wallet/RejectedWallet/Orders";
import WalletWithdrawal from "./Components/Admin/Wallet/WalletWithdrawal/Orders";

// Lead Dashborad
// import Lead from "./Components/lead-dashboard/Lead";

import CustomerDetails from "./Components/Admin/EmployeeManagement/CustomerDetails/Table";

//
import AppSetup from "./Components/Admin/Masters/AppSetup/Tables";

// Rating
import WDYLike from "./Components/Admin/Rating/WDYLike/Table";
import WDNYLike from "./Components/Admin/Rating/WDNYLike/Table";

// DeliveryBoy
import DeliveryBoy from "./Components/Admin/DeliveryBoy/Tables";

// Tiffin
import TiffinCategory from "./Components/Admin/Tiffin/TiffinCategory/Table";
import TiffinDishe from "./Components/Admin/Tiffin/TiffinDishe/Table";
import TiffinMenu from "./Components/Admin/Tiffin/TiffinMenu/Table";
import QuickTiffin from "./Components/Admin/Tiffin/QuickTiffin/Table";

// subscription
import Features from "./Components/Admin/Subscription/Features/Table";
import Plans from "./Components/Admin/Subscription/Plans/Table";
import LunchBox from "./Components/Admin/Subscription/LunchBox/Table";
import Subscription from "./Components/Admin/Subscription/Subscription/Table";

// Tiffin Orders
import TiffinSubscription from "./Components/Admin/TiffinOrders/Orders/Orders";
import TiffinDailyOrders from "./Components/Admin/TiffinOrders/TiffinDailyOrders/Orders";
import TiffinSunscriptionComplated from "./Components/Admin/TiffinOrders/TiffinSunscriptionComplated/Orders";
import TiffinSunscriptionCancelled from "./Components/Admin/TiffinOrders/TiffinSunscriptionCancelled/Orders";

// /order_assigned/order_assigned
import OrderAssigned from "./Components/Admin/OrderAssigned/OrderAssigned/Orders";
import PageNotFound from "./Components/Admin/PageNotFound/PageNotFound";
import { Context } from "./utils/context";
import { IDS } from "./utils/common";
import { elements } from "chart.js";

const App = () => {
  const { isAllow } = useContext(Context);
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [urls, setUrls] = useState([
    // {
    //   path: "/",
    //   element: <Login />,
    // },
    // {
    //   path: "/Header",
    //   element: <Header />,
    //   status: true,
    // },
    // {
    //   path: "/dashboard",
    //   element: <Dashboard />,
    //   status: true,
    // },
    // {
    //   path: "/masters/country",
    //   element: <Country />,
    //   status: IDS.Country.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/state",
    //   element: <State />,
    //   status: IDS.State.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/state",
    //   element: <State />,
    //   status: IDS.State.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/city",
    //   element: <City />,
    //   status: IDS.City.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/pincode",
    //   element: <Pincode />,
    //   status: IDS.Pincode.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/category",
    //   element: <Category />,
    //   status: IDS.Category.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/home-banner",
    //   element: <HomeBanner />,
    //   status: IDS.HomeBanner.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/tiffin-banner",
    //   element: <TiffinBanner />,
    //   status: IDS.TiffinBanner.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/restaurent",
    //   element: <TiffinBanner />,
    //   status: IDS.TiffinBanner.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/food-type",
    //   element: <FoodType />,
    //   status: IDS.FoodType.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/order-cancel-reason",
    //   element: <Order_cancel_reason />,
    //   status: IDS.OrderCancelReason.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/subscription-cancel-reason",
    //   element: <Subscription_cancel_reason />,
    //   status: IDS.SubscriptionCancelReason.some((value) =>
    //     isAllow.includes(value)
    //   ),
    // },
    // {
    //   path: "/masters/undelivered-reason",
    //   element: <Undelivered_reason />,
    //   status: IDS.UndeliveredReason.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/delivery-instructions",
    //   element: <Delivery_instructions />,
    //   status: IDS.DeliveryInstructions.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/cancellation-policy",
    //   element: <Cancellation_policy />,
    //   status: IDS.CancellationPolicy.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/masters/coupon-codes",
    //   element: <CouponCodes />,
    //   status: IDS.CupponCode.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/employee/role",
    //   element: <EmployeeRole />,
    //   status: IDS.Roles.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/employee/employee_details",
    //   element: <EmployeeDetails />,
    //   status: IDS.EmployeeDetails.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/employee/customer-details",
    //   element: <CustomerDetails />,
    //   status: IDS.CustomerDetails.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/customization/app-setup",
    //   element: <AppSetup />,
    //   status: IDS.Customization.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/rating/w_dn_y_like",
    //   element: <WDNYLike />,
    //   status: IDS.WDNYLike.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/rating/w_d_y_like",
    //   element: <WDYLike />,
    //   status: IDS.WDYLike.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/dishes/dishes",
    //   element: <Dishes />,
    //   status: IDS.Dishes.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/dishes/admin-dishes",
    //   element: <AdminDishes />,
    //   status: IDS.AdminDishes.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/dishes/recommended-dishes",
    //   element: <RecommendedDishes />,
    //   status: IDS.RecommendedDishes.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/orders/orders",
    //   element: <Orders />,
    //   status: IDS.Orders.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/orders/return-orders",
    //   element: <ReturnOrders />,
    //   status: IDS.ReturnOrders.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/orders/cancel-orders",
    //   element: <CancelOrders />,
    //   status: IDS.CancelOrders.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/orders/refund-orders",
    //   element: <RefundOrders />,
    //   status: IDS.RefundOrders.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/wallet/active-wallet",
    //   element: <ActiveWallet />,
    //   status: IDS.ActiveWallet.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/wallet/accepted-wallet",
    //   element: <AcceptedWallet />,
    //   status: IDS.AcceptedWallet.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/wallet/rejected-wallet",
    //   element: <RejectedWallet />,
    //   status: IDS.RejectedWallet.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/wallet/wallet-withdrawal",
    //   element: <WalletWithdrawal />,
    //   status: IDS.WalletWithdrawal.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/delivery-boy",
    //   element: <DeliveryBoy />,
    //   status: IDS.DeliveryBoy.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin/tiffin-category",
    //   element: <TiffinCategory />,
    //   status: IDS.TiffinCategory.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin/tiffin-dishe",
    //   element: <TiffinDishe />,
    //   status: IDS.TiffinDishe.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin/tiffin-menu",
    //   element: <TiffinMenu />,
    //   status: IDS.TiffinMenu.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin/quick-tiffin",
    //   element: <QuickTiffin />,
    //   status: IDS.QuickTiffin.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/subscription/features",
    //   element: <Features />,
    //   status: IDS.Features.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/subscription/plans",
    //   element: <Plans />,
    //   status: IDS.Plans.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/subscription/lunch-box",
    //   element: <LunchBox />,
    //   status: IDS.LunchBox.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/subscription/subscription",
    //   element: <Subscription />,
    //   status: IDS.Subscription.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin-orders/tiffin-subscription",
    //   element: <TiffinSubscription />,
    //   status: IDS.TiffinSubscription.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/tiffin-orders/tiffin-subscription-cancelled",
    //   element: <TiffinSunscriptionCancelled />,
    //   status: IDS.TiffinSubscriptionCancelled.some((value) =>
    //     isAllow.includes(value)
    //   ),
    // },
    // {
    //   path: "/tiffin-orders/tiffin-subscription-complated",
    //   element: <TiffinSunscriptionComplated />,
    //   status: IDS.TiffinSubscriptionComplated.some((value) =>
    //     isAllow.includes(value)
    //   ),
    // },
    // {
    //   path: "/tiffin-orders/tiffin-daily-orders",
    //   element: <TiffinDailyOrders />,
    //   status: IDS.TiffinDailyOrders.some((value) => isAllow.includes(value)),
    // },
    // {
    //   path: "/orders-assigned/orders-assigned",
    //   element: <OrderAssigned />,
    //   status: IDS.OrdersAssigned.some((value) => isAllow.includes(value)),
    // },
  ]);

  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/masters/country",
        element: <Country />,
        status: IDS.Country.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/state",
        element: <State />,
        status: IDS.State.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/state",
        element: <State />,
        status: IDS.State.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/city",
        element: <City />,
        status: IDS.City.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/pincode",
        element: <Pincode />,
        status: IDS.Pincode.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/category",
        element: <Category />,
        status: IDS.Category.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/home-banner",
        element: <HomeBanner />,
        status: IDS.HomeBanner.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/tiffin-banner",
        element: <TiffinBanner />,
        status: IDS.TiffinBanner.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/restaurent",
        element: <TiffinBanner />,
        status: IDS.TiffinBanner.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/food-type",
        element: <FoodType />,
        status: IDS.FoodType.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/order-cancel-reason",
        element: <Order_cancel_reason />,
        status: IDS.OrderCancelReason.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/subscription-cancel-reason",
        element: <Subscription_cancel_reason />,
        status: IDS.SubscriptionCancelReason.some((value) =>
          isAllow.includes(value)
        ),
      },
      {
        path: "/masters/undelivered-reason",
        element: <Undelivered_reason />,
        status: IDS.UndeliveredReason.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/delivery-instructions",
        element: <Delivery_instructions />,
        status: IDS.DeliveryInstructions.some((value) =>
          isAllow.includes(value)
        ),
      },
      {
        path: "/masters/cancellation-policy",
        element: <Cancellation_policy />,
        status: IDS.CancellationPolicy.some((value) => isAllow.includes(value)),
      },
      {
        path: "/masters/coupon-codes",
        element: <CouponCodes />,
        status: IDS.CupponCode.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/role",
        element: <EmployeeRole />,
        status: IDS.Roles.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/employee_details",
        element: <EmployeeDetails />,
        status: IDS.EmployeeDetails.some((value) => isAllow.includes(value)),
      },
      {
        path: "/employee/customer-details",
        element: <CustomerDetails />,
        status: IDS.CustomerDetails.some((value) => isAllow.includes(value)),
      },
      {
        path: "/customization/app-setup",
        element: <AppSetup />,
        status: IDS.Customization.some((value) => isAllow.includes(value)),
      },
      {
        path: "/rating/w_dn_y_like",
        element: <WDNYLike />,
        status: IDS.WDNYLike.some((value) => isAllow.includes(value)),
      },
      {
        path: "/rating/w_d_y_like",
        element: <WDYLike />,
        status: IDS.WDYLike.some((value) => isAllow.includes(value)),
      },
      {
        path: "/dishes/dishes",
        element: <Dishes />,
        status: IDS.Dishes.some((value) => isAllow.includes(value)),
      },
      {
        path: "/dishes/admin-dishes",
        element: <AdminDishes />,
        status: IDS.AdminDishes.some((value) => isAllow.includes(value)),
      },
      {
        path: "/dishes/recommended-dishes",
        element: <RecommendedDishes />,
        status: IDS.RecommendedDishes.some((value) => isAllow.includes(value)),
      },

      {
        path: "/orders/orders",
        element: <Orders />,
        status: IDS.Orders.some((value) => isAllow.includes(value)),
      },
      {
        path: "/orders/return-orders",
        element: <ReturnOrders />,
        status: IDS.ReturnOrders.some((value) => isAllow.includes(value)),
      },
      {
        path: "/orders/cancel-orders",
        element: <CancelOrders />,
        status: IDS.CancelOrders.some((value) => isAllow.includes(value)),
      },
      {
        path: "/orders/refund-orders",
        element: <RefundOrders />,
        status: IDS.RefundOrders.some((value) => isAllow.includes(value)),
      },
      {
        path: "/wallet/active-wallet",
        element: <ActiveWallet />,
        status: IDS.ActiveWallet.some((value) => isAllow.includes(value)),
      },
      {
        path: "/wallet/accepted-wallet",
        element: <AcceptedWallet />,
        status: IDS.AcceptedWallet.some((value) => isAllow.includes(value)),
      },

      {
        path: "/wallet/rejected-wallet",
        element: <RejectedWallet />,
        status: IDS.RejectedWallet.some((value) => isAllow.includes(value)),
      },
      {
        path: "/wallet/wallet-withdrawal",
        element: <WalletWithdrawal />,
        status: IDS.WalletWithdrawal.some((value) => isAllow.includes(value)),
      },
      {
        path: "/delivery-boy",
        element: <DeliveryBoy />,
        status: IDS.DeliveryBoy.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin/tiffin-category",
        element: <TiffinCategory />,
        status: IDS.TiffinCategory.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin/tiffin-dishe",
        element: <TiffinDishe />,
        status: IDS.TiffinDishe.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin/tiffin-menu",
        element: <TiffinMenu />,
        status: IDS.TiffinMenu.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin/quick-tiffin",
        element: <QuickTiffin />,
        status: IDS.QuickTiffin.some((value) => isAllow.includes(value)),
      },
      {
        path: "/subscription/features",
        element: <Features />,
        status: IDS.Features.some((value) => isAllow.includes(value)),
      },
      {
        path: "/subscription/plans",
        element: <Plans />,
        status: IDS.Plans.some((value) => isAllow.includes(value)),
      },
      {
        path: "/subscription/lunch-box",
        element: <LunchBox />,
        status: IDS.LunchBox.some((value) => isAllow.includes(value)),
      },
      {
        path: "/subscription/subscription",
        element: <Subscription />,
        status: IDS.Subscription.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin-orders/tiffin-subscription",
        element: <TiffinSubscription />,
        status: IDS.TiffinSubscription.some((value) => isAllow.includes(value)),
      },
      {
        path: "/tiffin-orders/tiffin-subscription-cancelled",
        element: <TiffinSunscriptionCancelled />,
        status: IDS.TiffinSubscriptionCancelled.some((value) =>
          isAllow.includes(value)
        ),
      },
      {
        path: "/tiffin-orders/tiffin-subscription-complated",
        element: <TiffinSunscriptionComplated />,
        status: IDS.TiffinSubscriptionComplated.some((value) =>
          isAllow.includes(value)
        ),
      },
      {
        path: "/tiffin-orders/tiffin-daily-orders",
        element: <TiffinDailyOrders />,
        status: IDS.TiffinDailyOrders.some((value) => isAllow.includes(value)),
      },
      {
        path: "/orders-assigned/orders-assigned",
        element: <OrderAssigned />,
        status: IDS.OrdersAssigned.some((value) => isAllow.includes(value)),
      },
    ]);
  }, [isAllow]);

  // console.log(urls);
  return (
    <div className="main d-flex">
      {headerText === "/" ? <></> : <Sidebar />}

      <Routes>
        <Route path="/" element={<Login />} />
        {urls?.map(
          (url) => url.status && <Route path={url.path} element={url.element} />
        )}
        {/* <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} /> */}
        {/* PageNotFound Start  */}
        <Route path="*" element={<PageNotFound />} /> {/* PageNotFound End  */}
      </Routes>
    </div>
  );
};

export default App;
