import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";
import { IDS } from "../../../utils/common";
library.add(fas);

const Sidebar = () => {
  const { isAllow } = useContext(Context);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [isOpen]);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section style={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
        <div className="top_section ">
          {isOpen && (
            <Link
              to="/dashboard"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              <h3 className="Itlogtitle">Milzac</h3>
            </Link>
          )}
          <div
            style={{ marginLeft: isOpen ? "30px" : "22px" }}
            className="bars"
          >
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0 mt-4">
          <Accordion defaultActiveKey={active}>
            <Accordion.Item
              eventKey="1"
              className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
              onClick={() => handleAccordionClick("1")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-house" />
                </div>
                <p
                  onClick={() => setIsOpen(!isOpen)}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>
              </Accordion.Header>
              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/dashboard")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("dashboard")}
                  >
                    <Link
                      to="/dashboard"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded "
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Dashboard
                      </p>
                    </Link>
                  </li>
                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/advanceDashboard")
                          ? "active block"
                          : "none"
                        : "none"
                      : "none"
                      }`}
                    onClick={() => handleInnerListItemClick("advanceDashboard")}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Advance Dashboard
                      </p>
                    </Link>
                  </li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************************Settings ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="2"
              className={`mb-1 ${activeAccordionItem === "2" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Settings
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Category.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/category")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("category")}
                    >
                      <Link
                        to="/masters/category"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Category
                        </p>
                      </Link>
                    </li>
                  )}

                  {/* {IDS.FoodType.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/food-type")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("food-type")}
                    >
                      <Link
                        to="/masters/food-type"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Food Type
                        </p>
                      </Link>
                    </li>
                  )} */}

                  {IDS.OrderCancelReason.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/order-cancel-reason")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("order-cancel-reason")
                      }
                    >
                      <Link
                        to="/masters/order-cancel-reason"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Order Cancel Reason
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.SubscriptionCancelReason.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/masters/subscription-cancel-reason"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("subscription-cancel-reason")
                      }
                    >
                      <Link
                        to="/masters/subscription-cancel-reason"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Subscription Cancel Reason
                        </p>
                      </Link>
                    </li>
                  )}

                  {/* /masters/undelivered-reason */}
                  {IDS.UndeliveredReason.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/undelivered-reason")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("undelivered-reason")
                      }
                    >
                      <Link
                        to="/masters/undelivered-reason"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Undelivered Reason
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.DeliveryInstructions.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/masters/delivery-instructions"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("delivery-instructions")
                      }
                    >
                      <Link
                        to="/masters/delivery-instructions"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Delivery Instructions
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.CancellationPolicy.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/cancellation-policy")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("cancellation-policy")
                      }
                    >
                      <Link
                        to="/masters/cancellation-policy"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Cancellation Policy
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************************Banners ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Banner"
              className={`mb-1 ${
                activeAccordionItem === "Banner" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Banner")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Banners
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.HomeBanner.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/home-banner")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("home-banner")}
                    >
                      <Link
                        to="/masters/home-banner"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Home Banner
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.TiffinBanner.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/tiffin-banner")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("tiffin-banner")}
                    >
                      <Link
                        to="/masters/tiffin-banner"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Banner
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Coupon Codes ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey=" CouponCodes"
              className={`mb-1 ${
                activeAccordionItem === " CouponCodes" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick(" CouponCodes")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Coupon Codes
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.CupponCode.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/coupon-codes")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("coupon-codes")}
                    >
                      <Link
                        to="/masters/coupon-codes"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Coupon Codes
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Customers ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey=" Customers"
              className={`mb-1 ${
                activeAccordionItem === " Customers" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick(" Customers")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Customers
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {" "}
                  {IDS.CustomerDetails.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/employee/customer-details"
                      className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes("/employee/customer-details")
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Customers Details
                        </p>
                      </li>
                    </Link>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************************Restaurent ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Restaurent"
              className={`mb-1 ${
                activeAccordionItem === "Restaurent" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Restaurent")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Restaurent
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Restaurent.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/restaurent")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("restaurent")}
                    >
                      <Link
                        to="/masters/restaurent"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Restaurent
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************Location************************************** */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Locations"
              className={`mb-1 ${
                activeAccordionItem === "Locations" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Locations")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Locations
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Country.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/country")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("country")}
                    >
                      <Link
                        to="/masters/country"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Country
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.State.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/state")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("state")}
                    >
                      <Link
                        to="/masters/state"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          State
                        </p>
                      </Link>
                    </li>
                  )}
                  {/* City */}
                  {IDS.City.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/city")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("city")}
                    >
                      <Link
                        to="/masters/city"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          City
                        </p>
                      </Link>
                    </li>
                  )}

                  {/* Pincode */}
                  {IDS.Pincode.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/pincode")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("pincode")}
                    >
                      <Link
                        to="/masters/pincode"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Pincode
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************Employee management************************************** */}

            <Accordion.Item
              eventKey="3"
              className={`mb-1 ${activeAccordionItem === "3" ? "active" : ""}`}
              onClick={() => handleAccordionClick("3")}
            >
              <Accordion.Header>
                <div className=" text-clr  me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-sitemap"
                    onClick={() => setIsOpen(!isOpen)}
                  />

                  <b></b>
                  <b></b>
                </div>
                <p style={{ display: isOpen ? "block" : "none" }}>
                  Employee Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Roles.some((value) => isAllow.includes(value)) && (
                    <Link to="/employee/role" className="text-decoration-none ">
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/employee/role")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() => handleInnerListItemClick("role")}
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Role
                        </p>
                      </li>
                    </Link>
                  )}
                  {IDS.EmployeeDetails.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/employee/employee_details")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("employee_details")
                      }
                    >
                      <Link
                        to="/employee/employee_details"
                        className="text-decoration-none"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Employee Details
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* *******************************************Dishes****************************************************** */}
            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="4"
              className={`mb-1 ${activeAccordionItem === "4" ? "active" : ""}`}
              onClick={() => handleAccordionClick("4")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-utensils" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Dishes
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.AdminDishes.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/dishes/admin-dishes")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-dishes")}
                    >
                      <Link
                        to="/dishes/admin-dishes"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Admin Dishes
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.RecommendedDishes.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/dishes/recommended-dishes")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("recommended-dishes")
                      }
                    >
                      <Link
                        to="/dishes/recommended-dishes"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Recommended Dishes
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.Dishes.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/dishes/dishes")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("dishes")}
                    >
                      <Link
                        to="/dishes/dishes"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Dishes
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ************************************************************  Customization ************************************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Customization"
              className={`mb-1 ${
                activeAccordionItem === "Customization" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Customization")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-utensils" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Customization
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Customization.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/customization/app-setup")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-dishes")}
                    >
                      <Link
                        to="/customization/app-setup"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          App Setup
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ************************************************************  DeliveryBoy ************************************************************* */}

            {/* <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="DeliveryBoy"
              className={`mb-1 ${
                activeAccordionItem === "DeliveryBoy" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("DeliveryBoy")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Delivery Boy
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.DeliveryBoy.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/delivery-boy")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-dishes")}
                    >
                      <Link
                        to="/delivery-boy"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Delivery Boy
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* ************************************************************  Rating ************************************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Rating"
              className={`mb-1 ${
                activeAccordionItem === "Rating" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Rating")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-utensils" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Rating
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.WDYLike.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/rating/w_d_y_like")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-dishes")}
                    >
                      <Link
                        to="/rating/w_d_y_like"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          What Do You Like
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.WDNYLike.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/rating/w_dn_y_like")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("admin-dishes")}
                    >
                      <Link
                        to="/rating/w_dn_y_like"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          What Do Not You Like
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************Orders ************************************** */}

            <Accordion.Item
              eventKey="5"
              className={`mb-1 ${activeAccordionItem === "5" ? "active" : ""}`}
              onClick={() => handleAccordionClick("5")}
            >
              <Accordion.Header>
                <div className=" text-clr me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-truck"
                    onClick={() => setIsOpen(!isOpen)}
                  />

                  <b></b>
                  <b></b>
                </div>
                <p style={{ display: isOpen ? "block" : "none" }}>Orders</p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Orders.some((value) => isAllow.includes(value)) && (
                    <Link to="/orders/orders" className="text-decoration-none ">
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/orders/orders")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() => handleInnerListItemClick("orders")}
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Orders
                        </p>
                      </li>
                    </Link>
                  )}

                  {IDS.CancelOrders.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/orders/cancel-orders"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/orders/cancel-orders")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("cancel-orders")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Cancel Orders
                        </p>
                      </li>
                    </Link>
                  )}

                  {/* {IDS.ReturnOrders.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/orders/return-orders"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/orders/return-orders")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("return-orders")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Return Orders
                        </p>
                      </li>
                    </Link>
                  )} */}

                  {IDS.RefundOrders.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/orders/refund-orders"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/orders/refund-orders")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("refund-orders")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Refund Orders
                        </p>
                      </li>
                    </Link>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************** Wallet  ************************************** */}

            <Accordion.Item
              eventKey="6"
              className={`mb-1 ${activeAccordionItem === "6" ? "active" : ""}`}
              onClick={() => handleAccordionClick("6")}
            >
              <Accordion.Header>
                <div className=" text-clr  me-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-wallet"
                    onClick={() => setIsOpen(!isOpen)}
                  />

                  <b></b>
                  <b></b>
                </div>
                <p style={{ display: isOpen ? "block" : "none" }}>Wallet</p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.ActiveWallet.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/wallet/active-wallet"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/wallet/active-wallet")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("active-wallet")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Active Wallet
                        </p>
                      </li>
                    </Link>
                  )}

                  {IDS.AcceptedWallet.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/wallet/accepted-wallet"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/wallet/accepted-wallet")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("accepted-wallet")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Accepted Wallet
                        </p>
                      </li>
                    </Link>
                  )}

                  {IDS.RejectedWallet.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/wallet/rejected-wallet"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/wallet/rejected-wallet")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("rejected-wallet")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Rejected Wallet
                        </p>
                      </li>
                    </Link>
                  )}

                  {IDS.WalletWithdrawal.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <Link
                      to="/wallet/wallet-withdrawal"
                      className="text-decoration-none "
                    >
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/wallet/wallet-withdrawal")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("wallet-withdrawal")
                        }
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Wallet Withdrawal
                        </p>
                      </li>
                    </Link>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Tiffin ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Tiffin"
              className={`mb-1 ${
                activeAccordionItem === "Tiffin" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Tiffin")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Tiffin
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.TiffinCategory.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/tiffin/tiffin-category")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("tiffin-category")
                      }
                    >
                      <Link
                        to="/tiffin/tiffin-category"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Category
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.TiffinDishe.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/tiffin/tiffin-dishe")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("tiffin-dishe")}
                    >
                      <Link
                        to="/tiffin/tiffin-dishe"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Dishe
                        </p>
                      </Link>
                    </li>
                  )}

                  {/* {IDS.TiffinMenu.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/tiffin/tiffin-menu")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("tiffin-menu")}
                    >
                      <Link
                        to="/tiffin/tiffin-menu"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Menu
                        </p>
                      </Link>
                    </li>
                  )} */}

                  {IDS.QuickTiffin.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/tiffin/quick-tiffin")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("quick-tiffin")}
                    >
                      <Link
                        to="/tiffin/quick-tiffin"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Quick Tiffin
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Subscription ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="Subscription"
              className={`mb-1 ${
                activeAccordionItem === "Subscription" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("Subscription")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Subscription
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.Features.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/subscription/features")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("features")}
                    >
                      <Link
                        to="/subscription/features"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Features
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.Plans.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/subscription/plans")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("plans")}
                    >
                      <Link
                        to="/subscription/plans"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Plans
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.LunchBox.some((value) => isAllow.includes(value)) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/subscription/lunch-box")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("lunch-box")}
                    >
                      <Link
                        to="/subscription/lunch-box"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Lunch Box
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.Subscription.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/subscription/subscription")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("subscription")}
                    >
                      <Link
                        to="/subscription/subscription"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Subscription
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Tiffin Orders ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="TiffinOrders"
              className={`mb-1 ${
                activeAccordionItem === "TiffinOrders" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("TiffinOrders")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Tiffin Orders
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.TiffinSubscription.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/tiffin-orders/tiffin-subscription"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("tiffin-subscription")
                      }
                    >
                      <Link
                        to="/tiffin-orders/tiffin-subscription"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Subscription
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.TiffinDailyOrders.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/tiffin-orders/tiffin-daily-orders"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("tiffin-daily-orders")
                      }
                    >
                      <Link
                        to="/tiffin-orders/tiffin-daily-orders"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Daily Orders
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.TiffinSubscriptionComplated.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/tiffin-orders/tiffin-subscription-complated"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick(
                          "tiffin-subscription-complated"
                        )
                      }
                    >
                      <Link
                        to="/tiffin-orders/tiffin-subscription-complated"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Subscription Complated
                        </p>
                      </Link>
                    </li>
                  )}

                  {IDS.TiffinSubscriptionCancelled.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/tiffin-orders/tiffin-subscription-cancelled"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick(
                          "tiffin-subscription-cancelled"
                        )
                      }
                    >
                      <Link
                        to="/tiffin-orders/tiffin-subscription-cancelled"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tiffin Subscription Cancelled
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>

            {/* **************************************************************** Orders Assigned ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="OrdersAssigned"
              className={`mb-1 ${
                activeAccordionItem === "OrdersAssigned" ? "active" : ""
              }`}
              onClick={() => handleAccordionClick("OrdersAssigned")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Orders Assigned
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {IDS.OrdersAssigned.some((value) =>
                    isAllow.includes(value)
                  ) && (
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/orders-assigned/orders-assigned"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("orders-assigned")
                      }
                    >
                      <Link
                        to="/orders-assigned/orders-assigned"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Orders Assigned
                        </p>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>
            {/* =========================================================================================================================================== */}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
