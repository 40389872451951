import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import plus from "../../../assets/icons/a1.png";
import colunms from "../../../assets/icons/LINES.png";
import pen from "../../../assets/icons/pen.png";
import basket from "../../../assets/icons/basket.png";
import search1 from "../../../assets/icons/search.png";
import top from "../../../assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Header/Header";
import { Context } from "../../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { getAllOrderDetails } from "../../../../../utils/apis/orders/Order";

// ***************************************************************************************************************************************************************************
const EditOffCanvance = (props) => {
  const { postData, getData, RequiredIs } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();

  const GetEditData = async () => {
    const response = await getAllOrderDetails(id);
    // const roleData = response.data.role;
    setData(response.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "100%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Address / Order Details</Card.Title>
              <hr />
              <table className="table table-responsive table-bordered">
                <thead>
                  <tr className="">
                    <td>Name</td>
                    <td>{data?.address?.address?.name}</td>
                    <td>Add Type </td>
                    <td>{data?.address?.address?.add_type}</td>
                  </tr>
                  <tr className="">
                    <td>House</td>
                    <td>{data?.address?.address?.house}</td>
                    <td>Apartment </td>
                    <td>{data?.address?.address?.apartment}</td>
                  </tr>

                  <tr className="">
                    <td>landmark</td>
                    <td>{data?.address?.address?.landmark}</td>
                    {/* <td>Apartment </td>
                    <td>{data?.address?.address?.full_add_o}</td> */}
                  </tr>
                </thead>
              </table>
              <table className="table table-responsive table-bordered">
                <thead>
                  <tr className="">
                    <td>Order Id </td>
                    <td>Name</td>
                    <td>Quantity </td>
                    <td>Customize Spice</td>
                  </tr>
                </thead>

                <tbody>
                  {data?.data?.map((value, index) => (
                    <tr key={index}>
                      <td> {value?.order_id}</td>
                      <td>{value?.dish?.name}</td>
                      <td>{value?.quantity}</td>
                      <td>{value?.customize_spice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditOffCanvance;
// *
