import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search, status) => {
  try {
    let url = `/dishes/dishes?`;
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    url += `&status=${status}`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Get = async (id) => {
  try {
    return await getData(`/dishes/dishes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/dishes/dishes`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id, data) => {
  try {
    return await postData(`/dishes/dishes/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const UpdateDiscount = async (id, data) => {
  try {
    return await postData(`/dishes/dishes/discount/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Status = async (id) => {
  try {
    return await editStatusData(`/dishes/dishes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/dishes/dishes/${id}`);
  } catch (error) {
    console.error(error);
  }
};
