import React, { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
// import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Orderlist from "./Orderlist";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
// import {
//   Chart as ChartJS,
//  CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// Register necessary components with ChartJS

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGauge, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactApexChart from "react-apexcharts";
import {
  getAllData,
  getDashboardOrdersCountByMonth,
  getDashBoardUsersAgeGraph,
} from "../../../../utils/apis/Dashborad/Dashborad";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
library.add(fas);
function Table() {
  const labels = [
    "0 - 10",
    "11 - 20",
    "21 - 30",
    "31 - 40",
    "41 - 50",
    "51 - 60",
    "61 - 70",
    "71 - 80",
    "81 - 90",
    "91 - 100",
  ];

  const data1 = {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  const [data, setData] = useState({});
  const [ageGraph, setAgeGraph] = useState({
    labels: [],
    datasets: [
      {
        label: "Users Age Group",
        data: [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [data3, setData3] = useState({
    labels: [],
    datasets: [
      {
        label: "Orders Count By Month",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  });

  const getData = async () => {
    {
      const res = await getDashBoardUsersAgeGraph();

      await setAgeGraph({
        labels: res?.data?.groups || [],
        datasets: [
          {
            label: "Users Age Group",
            data: res?.data?.data || [],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
    {
      const res = await getAllData();
      await setData(res?.data);
    }
    {
      const res = await getDashboardOrdersCountByMonth();
      // console.log(res);
      await setData3({
        labels: res.data?.months,
        datasets: [
          {
            label: "Orders Count By Month",
            data: res.data?.data,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const labels3 = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  // ];

  // const data3 = {
  //   labels: labels3,
  //   datasets: [
  //     {
  //       label: "Orders Count By Month",
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       fill: false,
  //       borderColor: "rgb(75, 192, 192)",
  //       tension: 0.1,
  //     },
  //   ],
  // };

  const navigate = useNavigate();
  return (
    <div className="main-advancedashboard">
      <Header icon={faGauge} title={" Dashboard"} link={"/"} />
      <section className="AdvanceDashboard mt-4">
        <div className="AdvanceDashboardTabs">
          <div className="row justify-content-end me-0 ms-0">
            <div className="col-xxl-12 col-12 p-0">
              <div className="tab-content" id="myTabContent">
                {/* overview tab start */}
                <div
                  className="tab-pane fade show active"
                  id="Overview-tab-pane"
                  role="tabpanel"
                  aria-labelledby="Overview-tab"
                  tabindex="0"
                >
                  <div className="AdvanceDashboardTabsContent">
                    <div className="row ">
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis">
                          <div className="Total-Regis-holder">
                            {/* <p className="sub-text">Total Registration</p> */}
                            <div
                              className="user"
                              onClick={() =>
                                navigate("/employee/customer-details")
                              }
                            >
                              <p className="sub-text">Total Registration</p>
                              <div className="border-main"></div>
                              <p className="sub-text">
                                {data?.total_register_users}
                              </p>
                            </div>
                            {/* <div className="user">
                              <p className="sub-text"> </p>
                              <div className="border-main1"></div>
                              <p className="sub-text">
                                {data?.active_register_users}
                              </p>
                            </div> */}
                          </div>
                          {/* <div className="sub-text-holder">
                            <p className="sub-text">
                              Today's User : {data?.total_today_register_users}
                            </p>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis2">
                          <div className="Total-Regis-holder">
                            <div
                              className="user"
                              onClick={() =>
                                navigate("/tiffin-orders/tiffin-subscription")
                              }
                            >
                              <p className="sub-text">
                                Tiffin Subscribers/Subscribers Plan
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_subscription || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis3">
                          <div className="Total-Regis-holder">
                            <div
                              className="user"
                              onClick={() =>
                                navigate("/tiffin-orders/tiffin-subscription")
                              }
                            >
                              <p className="sub-text">
                                Tiffin Single/Single Plan
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_single || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis4">
                          <div className="Total-Regis-holder">
                            <div
                              className="user"
                              onClick={() => navigate("/orders/cancel-orders")}
                            >
                              <p className="sub-text">Total Cancelled Orders</p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_cancelled_orders}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis4">
                          <div className="Total-Regis-holder">
                            <div
                              className="user"
                              onClick={() => navigate("/orders/orders")}
                            >
                              <p className="sub-text">Total Orders</p>
                              <div className="border-main"></div>
                              <p className="sub-text">{data?.total_orders}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis3">
                          <div className="Total-Regis-holder">
                            <div
                              className="user"
                              onClick={() => navigate("/orders/orders")}
                            >
                              <p className="sub-text">Total Delivered Orders</p>
                              <div className="border-main"></div>
                              <p className="sub-text">
                                {data?.total_delivered_orders}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis2">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">
                                Restaurent Profit & Loss
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_profit_loss || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">
                                Restaurent Today Profit & Loss
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.today_total_profit_loss || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">
                                Restaurent Total Online
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_online || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis2">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">Restaurent Total COD</p>
                              <div className="main">:</div>
                              <p className="sub-text">{data?.total_cod || 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis3">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">
                                Restaurent Today Online
                              </p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.today_total_online || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis4">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">Restaurent Today COD</p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.today_total_cod || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <div className="Total-Regis4">
                          <div className="Total-Regis-holder">
                            <div className="user">
                              <p className="sub-text">Monthly Order</p>
                              <div className="main">:</div>
                              <p className="sub-text">
                                {data?.total_returned_orders || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <Row>
                      <div className="col-md-6">
                        <Bar data={ageGraph} options={options} />
                      </div>
                      <div className="col-md-6">
                        <Line data={data3} />
                      </div>
                    </Row>
                  </div>
                </div>
                {/* overview tab end */}
                {/* ***************************************Graph*********************************** */}
                {/* <div className="Graph">
                  <div id="chart">
                    <ReactApexChart
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="line"
                      height={550}
                    />
                  </div>
                  <div id="html-dist"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Orderlist />
      </section>
    </div>
  );
}

export default Table;
