import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import {
  City,
  Country,
  State,
  allDaysType,
  allFoodType,
  allPincode,
  allTiffinCategory,
  allTiffinDishes,
  allWeekDays,
} from "../../../../utils/apis/masters/masters";
import { Update } from "../../../../utils/apis/tiffin/tiffinmenu";
import JoditEditor from "jodit-react";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/tiffin/tiffin-menu/${id}`);
    reset(response?.data);
    GetDishes(response?.data.tiffin_category_id?.value);
  };

  const [category, setCategory] = useState([]);
  const [weekDays, setweekDays] = useState([]);
  const [dayType, setdayType] = useState([]);
  const [dishes, setdishes] = useState([]);

  const GetAllMasters = async () => {
    {
      const response = await allTiffinCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "tiffin_category_id"));
      }
    }

    {
      const response = await allWeekDays();
      if (response?.success) {
        setweekDays(await Select2Data(response?.data, "week_day_id"));
      }
    }

    {
      const response = await allDaysType();
      if (response?.success) {
        setdayType(await Select2Data(response?.data, "day_type_id"));
      }
    }
  };

  const GetDishes = async (id) => {
    const response = await allTiffinDishes(id);
    if (response?.success) {
      setdishes(await Select2Data(response?.data, "dishe_id"));
    }
  };

  useEffect(() => {
    GetAllMasters();
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      tiffin_category_id: "",
      week_day_id: "",
      day_type_id: "",
      dishe_id: [],
    },
  });

  const onSubmit = async (data) => {
    try {
      const response = await Update(id, data);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(false);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Tiffin Menu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Week Day</Form.Label>
                    <Controller
                      name="week_day_id"
                      {...register("week_day_id", {
                        required: "Select Week Day",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.week_day_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={weekDays}
                        />
                      )}
                    />
                    {errors.week_day_id && (
                      <span className="text-danger">
                        {errors.week_day_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Day Type</Form.Label>
                    <Controller
                      name="day_type_id"
                      {...register("day_type_id", {
                        required: "Select Day Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.day_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={dayType}
                        />
                      )}
                    />
                    {errors.day_type_id && (
                      <span className="text-danger">
                        {errors.day_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Category</Form.Label>
                    <Controller
                      name="tiffin_category_id"
                      {...register("tiffin_category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.tiffin_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          onChange={(s) => {
                            GetDishes(s?.value);
                            setValue("tiffin_category_id", s);
                          }}
                          options={category}
                        />
                      )}
                    />
                    {errors.tiffin_category_id && (
                      <span className="text-danger">
                        {errors.tiffin_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={12}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Dishes</Form.Label>
                    <Controller
                      name="dishe_id"
                      {...register("dishe_id", {
                        required: "Select Dishes",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.dishe_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          isMulti
                          options={dishes}
                        />
                      )}
                    />
                    {errors.dishe_id && (
                      <span className="text-danger">
                        {errors.dishe_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
