import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import {
  City,
  Country,
  State,
  allFoodType,
  allPincode,
  allResturentCategory,
} from "../../../../utils/apis/masters/masters";
import { Update, UpdateDiscount } from "../../../../utils/apis/dishes/dishes";
import JoditEditor from "jodit-react";
library.add(fas);

const EditDiscountOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/dishes/dishes/${id}`);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      category_id: "",
      food_type_id: "",
      name: "",
      time: "",
      price: "",
      packaging_charges: "",
      customize_spice: 0,
      free_delivery: 0,
      best_seller: 0,
      image: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();

      finalData.append("discount", data?.discount);

      const response = await UpdateDiscount(id, finalData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }

      setTimeout(() => {
        setShowModal(false);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Dishes Discount (Dishes Accepted)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Discount</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="discount"
                          placeholder={"Discount"}
                          max={100}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.discount,
                          })}
                          {...register("discount", {
                            // required: "Discount is required",
                            validate: (value) => {
                              // if (!value) return "Discount is required";
                              if (
                                value &&
                                (parseFloat(value) < 0 ||
                                  parseFloat(value) > 100)
                              ) {
                                return "Discount must be between 0% and 100%";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.discount && (
                        <span className="text-danger">
                          {errors.discount.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditDiscountOffCanvance;
