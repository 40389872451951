import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async () => {
  try {
    let url = `/dashboard/dashboard?`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getDashBoardUsersAgeGraph = async () => {
  try {
    let url = `/dashboard/dashboard/user-age-graph`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const getDashboardOrdersCountByMonth = async () => {
  try {
    let url = `/dashboard/dashboard/orders-count-by-month`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

// export const getSingleData = async () => {
//   try {
//     return await getData(`/admin/investors/investors`);
//   } catch (error) {
//     console.error(error);
//   }
// };
