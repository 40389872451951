import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import {
  City,
  Country,
  State,
  allCategory,
  allDishesForBanner,
  allPincode,
} from "../../../../utils/apis/masters/masters";

library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [min, setMin] = useState(0);

  const type = [
    {
      value: "Category",
      label: "Category",
    },
    {
      value: "Dishes",
      label: "Dishes",
    },
  ];
  const [category, setCategory] = useState([]);
  const [dishes, setDishes] = useState([]);
  const GetEditData = async () => {
    const response = await getData(`/masters/home-banner/${id}`);
    reset(response?.data);
  };

  const [country, setCountry] = useState([]);

  const GetAllCountry = async () => {
    {
      const response = await allCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "category_id"));
      }
    }

    {
      const response = await allDishesForBanner();

      if (response?.success) {
        setDishes(await Select2Data(response?.data, "dishes_id"));
      }
    }
  };

  useEffect(() => {
    GetAllCountry();

    GetEditData();
  }, []);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    setError,
  } = useForm();

  // const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();

      finalData.append("name", data?.name);

      finalData.append("link_type", data?.link_type?.value);

      if (data?.category_id) {
        finalData.append("category_id", data?.category_id?.value);
      }
      if (data?.dishes_id) {
        finalData.append("dishes_id", data?.dishes_id?.value);
      }

      finalData.append("image", data?.image[0]);
      const response = await postData(`/masters/home-banner/${id}`, finalData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }

      setTimeout(() => {
        setShowModal(false);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const [imageFile, setImageFile] = useState(null);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Restaurent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label> Name</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder=" Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: " Name is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={3}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Link Type</Form.Label>
                    <Controller
                      name="link_type"
                      control={control}
                      rules={{ required: "Select Type" }}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.link_type
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                          {...field}
                          options={type}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            setValue("link_type", selectedOption);
                            reset(getValues());
                          }}
                        />
                      )}
                    />
                    {errors.link_type && (
                      <span className="text-danger">
                        {errors.link_type.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              {getValues("link_type")?.value == "Category" ? (
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <Row className="row justify-content-center mb-2">
                      <Form.Label>Category</Form.Label>
                      <Controller
                        name="category_id"
                        {...register("category_id", {
                          required: "Select Category",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.category_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={category}
                          />
                        )}
                      />
                      {errors.category_id && (
                        <span className="text-danger">
                          {errors.category_id.message}
                        </span>
                      )}
                    </Row>
                  </div>
                </Col>
              ) : (
                <Col md={6}>
                  <div className="main-form-section mt-3">
                    <Row className="row justify-content-center mb-2">
                      <Form.Label>Dishes</Form.Label>
                      <Controller
                        name="dishes_id"
                        {...register("dishes_id", {
                          required: "Select Dishes",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.dishes_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={dishes}
                          />
                        )}
                      />
                      {errors.dishes_id && (
                        <span className="text-danger">
                          {errors.dishes_id.message}
                        </span>
                      )}
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Media
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            // required: "Media is required",
                          })}
                          accept=".jpg, .jpeg, .png, .mp4, .mov"
                          multiple={false}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setImageFile(file);
                            }
                          }}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Media Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && (
                        <div className="media-preview-container">
                          {imageFile.type.startsWith("image/") ? (
                            <img
                              src={URL.createObjectURL(imageFile)}
                              alt="Preview"
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          ) : imageFile.type.startsWith("video/") ? (
                            <video
                              src={URL.createObjectURL(imageFile)}
                              controls
                              className="media-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          ) : null}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
