import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../../api";

export const getAllData = async (
  currentPage,
  perPage,
  search,
  searchStatus
) => {
  try {
    let url = `/masters/coupon-codes?page=${currentPage}&per_page=${perPage}&term=${search}`;
    if (searchStatus?.value || searchStatus?.value == 0) {
      url += `&status=${searchStatus?.value}`;
    }
    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const changeDataStatus = async (id) => {
  try {
    return await editStatusData(`/masters/coupon-codes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (recordToDeleteId) => {
  try {
    return await deleteData(`/masters/coupon-codes/${recordToDeleteId}`);
  } catch (error) {
    console.error(error);
  }
};

export const add = async (finalData) => {
  try {
    return await postData(`/masters/coupon-codes`, finalData);
  } catch (error) {
    console.error(error);
  }
};

export const edit = async (id, finalData) => {
  try {
    return await postData(`/masters/coupon-codes/${id}`, finalData);
  } catch (error) {
    console.error(error);
  }
};

export const getsingledata = async (id) => {
  try {
    return await getData(`/masters/coupon-codes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

// export const getsinglebrand = async () => {
//   try {
//     return await getData(`/without-login/masters/working-type`);
//   } catch (error) {
//     console.error(error);
//   }
// };
