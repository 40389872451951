import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import {
  City,
  Country,
  State,
  allDaysType,
  allFeatures,
  allFoodType,
  allPincode,
  allTiffinCategory,
  allTiffinDishes,
  allWeekDays,
} from "../../../../utils/apis/masters/masters";
import { Update } from "../../../../utils/apis/Subscription/LunchBox";
import JoditEditor from "jodit-react";
library.add(fas);

const EditOffCanvance = (props) => {
  const { getData, Select2Data, IMG_URL } = useContext(Context);

  const id = props.show;

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/subscription/lunch-box/${id}`);
    reset(response?.data);
  };

  const [dayType, setdayType] = useState([]);

  const GetAllMasters = async () => {
    {
      const response = await allDaysType();
      if (response?.success) {
        setdayType(await Select2Data(response?.data, "day_type_id"));
      }
    }
  };

  useEffect(() => {
    GetAllMasters();
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      tiffin_category_id: "",
      week_day_id: "",
      day_type_id: "",
      dishe_id: [],
    },
  });
  const imageFile = watch("image");
  const onSubmit = async (data) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", data.name);
      formDataToSend.append("price", data.price);
      formDataToSend.append("deposite", data.deposite);

      if (data?.image && data.image.length > 0) {
        formDataToSend.append("image", data.image[0]);
      }

      const response = await Update(id, formDataToSend);

      if (response?.success) {
        await setShowModal({
          code: response.code,
          message: response.message,
        });
      } else {
        await setShowModal({
          code: response.code,
          message: response.message,
        });
      }
      setTimeout(() => {
        setShowModal(false);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Lunch Box
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder={"Name"}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          name={`name`}
                          {...register(`name`, {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors?.name && (
                        <span className="text-danger">
                          {errors?.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Price"}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.price,
                          })}
                          name={`price`}
                          {...register(`price`, {
                            required: "Price is required",
                            validate: (value) => {
                              // if (!value) return "Discount is required";
                              if (value && parseFloat(value) < 0) {
                                return "Price is min 0 or greate";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                          // {...register(`subscription_item.${index}.tiffin_category_id`, {
                          //   required: "Discount is required",
                          //   validate: (value) => {
                          //     // if (!value) return "Discount is required";
                          //     // if (
                          //     //   value &&
                          //     //   (parseFloat(value) < 0 ||
                          //     //     parseFloat(value) > 100)
                          //     // ) {
                          //     //   return "Discount must be between 0% and 100%";
                          //     // }
                          //     return true; // Return true for valid input
                          //   },
                          // })}
                        />
                      </InputGroup>
                      {errors?.price && (
                        <span className="text-danger">
                          {errors?.price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Deposite</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Deposite"}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.deposite,
                          })}
                          name={`deposite`}
                          {...register(`deposite`, {
                            required: "Deposite is required",
                            validate: (value) => {
                              // if (!value) return "Discount is required";
                              if (value && parseFloat(value) < 0) {
                                return "Deposite is min 0 or greate";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors?.deposite && (
                        <span className="text-danger">
                          {errors?.deposite.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            // required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {typeof getValues("image") == "string" ? (
                        <div className="image-preview-container">
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      ) : (
                        imageFile &&
                        imageFile?.length > 0 && (
                          <div className="image-preview-container">
                            <img
                              // src={URL.createObjectURL(getValues("image")[0])}
                              src={URL?.createObjectURL(imageFile[0])}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
