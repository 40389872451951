import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    image: null,
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (formData.name.length > 81) {
      errors.name = "Name Must be 80 characters Maximum";
    }
    // if (!formData.image) {
    //   errors.image = "Image is required";
    // }
    return errors;
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        // formDataToSend.append("image", formData.image);

        const response = await postData("/rating/w_dn_y_like", formDataToSend);
        if (response?.success) {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Blog Feed</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add What Do Not You Like</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="blogfeedclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-5">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          What Do Not You Like
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                maxLength={50}
                                                placeholder="What Do Not You Like "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.name && (
                                              <span style={errorStyle}>
                                                {errors?.name}
                                              </span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                {/* New field for uploading an image */}
                                {/* <Row className="justify-content-center mt-3">
                                  <Form.Label column sm={3}>
                                    Image
                                  </Form.Label>

                                  <Col sm={6}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          accept="image/*"
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      {errors?.image && (
                                        <span style={errorStyle}>
                                          {errors?.image}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Col md={6}>
                                  <Row>
                                    <Col md={12}>
                                      {imagePreview ? (
                                        <img
                                          src={imagePreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            margin: "2px",
                                            marginLeft: "300px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        <p style={{ marginLeft: "100px" }}>
                                          No image selected
                                        </p>
                                      )}
                                    </Col>
                                  </Row>
                                </Col> */}

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
