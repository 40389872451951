import React from "react";
import "./Orders.css";
import Orderstock from "./Orderstock/Orderstock";
import Ordertable from "./Ordertable/Ordertable";
import Header from "../../Header/Header";

const Orders = () => {
  return (
    <>
       
      {/* <Orderstock /> */}
      <Ordertable />
    </>
  );
};

export default Orders;
