import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/tiffin-orders/tiffin-daily-orders/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderDetails = async (id) => {
  try {
    return await getData(
      `/tiffin-orders/tiffin-daily-orders/all-orders-details/${id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderStatus = async () => {
  try {
    return await getData(`/tiffin-orders/tiffin-daily-orders/all-order-status`);
  } catch (error) {
    console.error(error);
  }
};

export const postAllOrderStatus = async (data, order) => {
  try {
    return await postData(
      `/tiffin-orders/tiffin-daily-orders/all-order-status`,
      {
        ids: data,
        order: order?.value,
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const postAllDeliveryBoyAssign = async (data, delivery_boy_id) => {
  try {
    return await postData(
      `/tiffin-orders/tiffin-daily-orders/all-assigne-orders`,
      {
        ids: data,
        delivery_boy_id: delivery_boy_id?.value,
      }
    );
  } catch (error) {
    console.error(error);
  }
};
