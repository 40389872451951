import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search, status) => {
  try {
    let url = `/tiffin/tiffin-dishe?`;
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    url += `&status=${status}`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Get = async (id) => {
  try {
    return await getData(`/tiffin/tiffin-dishe/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/tiffin/tiffin-dishe`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id, data) => {
  try {
    return await postData(`/tiffin/tiffin-dishe/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const UpdateDiscount = async (id, data) => {
  try {
    return await postData(`/tiffin/tiffin-dishe/discount/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Status = async (id) => {
  try {
    return await editStatusData(`/tiffin/tiffin-dishe/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/tiffin/tiffin-dishe/${id}`);
  } catch (error) {
    console.error(error);
  }
};
