export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const IDS = {
  Roles: [1],
  Country: [2],
  State: [3],
  City: [4],
  Pincode: [5],
  Restaurent: [6],
  Category: [7],
  HomeBanner: [8],
  TiffinBanner: [9],
  TiffinCategory: [10],
  FoodType: [11],
  AdminDishes: [12],
  RecommendedDishes: [13],
  Dishes: [14],
  OrderCancelReason: [15],
  DeliveryInstructions: [16],
  CancellationPolicy: [17],
  CupponCode: [18],
  Orders: [19],
  ReturnOrders: [20],
  RefundOrders: [21],
  ActiveWallet: [22],
  Customization: [23],
  WDYLike: [24],
  WDNYLike: [25],
  AcceptedWallet: [26],
  RejectedWallet: [27],
  CancelOrders: [28],
  DeliveryBoy: [29],
  EmployeeDetails: [30],
  TiffinDishe: [31],
  TiffinMenu: [32],
  Features: [33],
  Subscription: [34],
  Plans: [35],
  LunchBox: [36],
  QuickTiffin: [37],
  TiffinSubscription: [38],
  TiffinDailyOrders: [39],
  UndeliveredReason: [40],
  TiffinSubscriptionComplated: [41],
  TiffinSubscriptionCancelled: [42],
  OrdersAssigned: [43],
  SubscriptionCancelReason: [44],
  CustomerDetails: [45],
  WalletWithdrawal: [46],

  // Roles: [1, 2],
  // Country: [6, 7, 8, 9],
  // State: [11, 12, 13, 14],
  // City: [16, 17, 18, 19],
  // Pincode: [21, 22, 23, 24],
  // Restaurent: [26, 27, 28, 29],
  // Category: [31, 32, 33, 34],
  // HomeBanner: [36, 37, 38, 39],
  // TiffinBanner: [41, 42, 43, 44],
  // TiffinCategory: [46, 47, 48, 49],
  // FoodType: [51, 52, 53, 54],
  // AdminDishes: [56, 57, 58, 59],
  // RecommendedDishes: [61, 62, 63, 64],
  // Dishes: [66, 67, 68, 69],

  usertype: {
    Restaurent: "Restaurent",
    Admin: "Admin",
  },
  order_type: {
    Restaurent: "Restaurent",
    Tiffin: "Tiffin",
  },
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);

  // Extract individual components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // getUTCMonth returns month from 0-11
  const day = date.getUTCDate();
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Add leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  // Format components as needed
  const formattedDate = `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  return formattedDate;
}
