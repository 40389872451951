import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import {
  City,
  Country,
  State,
  allCategory,
  allDaysType,
  allFoodType,
  allPincode,
  allTiffinCategory,
  allTiffinDishes,
  allWeekDays,
  allFeatures,
  allDishes,
} from "../../../../utils/apis/masters/masters";
import { Context } from "../../../../utils/context";
import { Create, ImageUpload } from "../../../../utils/apis/tiffin/QuickTiffin";
import JoditEditor from "jodit-react";
import { Label } from "recharts";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [category, setCategory] = useState([]);

  const [dishes, setdishes] = useState([]);

  const GetAllMasters = async () => {
    {
      const response = await allTiffinCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "tiffin_category_id"));
      }
    }
  };

  const getTiffinCategoryDishes = async (id) => {
    {
      const response = await allDishes(id);
      if (response?.success) {
        setdishes(await Select2Data(response?.data, "tiffin_dishe_id"));
      }
    }
  };

  useEffect(() => {
    GetAllMasters();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({});

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    // console.log(data);
    // console.log(data?.quick_tiffin_items?.length);
    // return;

    try {
      if (data.quick_tiffin_items.length) {
        clearErrors();

        const response = await Create(data);
        if (response?.success) {
          const formDataToSend = new FormData();
          if (data?.image && data.image.length > 0) {
            formDataToSend.append("image", data.image[0]);
          }
          await ImageUpload(response.data.id, formDataToSend);

          await setShowModal({
            code: response.code,
            message: response.message,
          });
        } else {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        }
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } else {
        setError("quick_tiffin_items", {
          type: "manual",
          message: "Add Quick Tiffin Items Items",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quick_tiffin_items",
  });

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Quick Tiffin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Quick Tiffin Name</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Quick Tiffin Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Quick Tiffin Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {fields.map((variant, index) => (
                <div key={variant.id} className="main-form-section mt-3">
                  <Row>
                    <Col md={5}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tiffin Category</Form.Label>
                          <Controller
                            name={`quick_tiffin_items.${index}.tiffin_category_id`}
                            {...register(
                              `quick_tiffin_items.${index}.tiffin_category_id`,
                              {
                                required: "Select Tiffin Category",
                              }
                            )}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors?.quick_tiffin_items?.[
                                      index
                                    ]?.tiffin_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={category}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  getTiffinCategoryDishes(selectedOption.value); // Update Controller's value
                                  setValue(
                                    `quick_tiffin_items.${index}.tiffin_category_id`,
                                    selectedOption
                                  );
                                  setValue(
                                    `quick_tiffin_items.${index}.tiffin_dishe_id`,
                                    ""
                                  );
                                }}
                              />
                            )}
                          />
                          {errors?.quick_tiffin_items?.[index]
                            ?.tiffin_category_id && (
                            <span className="text-danger">
                              {
                                errors?.quick_tiffin_items?.[index]
                                  ?.tiffin_category_id.message
                              }
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={5}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tiffin Category</Form.Label>
                          <Controller
                            name={`quick_tiffin_items.${index}.tiffin_dishe_id`}
                            {...register(
                              `quick_tiffin_items.${index}.tiffin_dishe_id`,
                              {
                                required: "Select Tiffin Category",
                              }
                            )}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors?.quick_tiffin_items?.[
                                      index
                                    ]?.tiffin_dishe_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={dishes}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value); // Update Controller's value
                                  setValue(
                                    `quick_tiffin_items.${index}.tiffin_dishe_id`,
                                    selectedOption
                                  );
                                }}
                              />
                            )}
                          />
                          {errors?.quick_tiffin_items?.[index]
                            ?.tiffin_dishe_id && (
                            <span className="text-danger">
                              {
                                errors?.quick_tiffin_items?.[index]
                                  ?.tiffin_dishe_id.message
                              }
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <button
                        className="mt-3 add-varient"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        Remove Items
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}

              <div className="main-form-section mt-3"></div>
              <hr />
              {errors?.quick_tiffin_items && (
                <span className="text-danger">
                  {errors?.quick_tiffin_items.message}
                </span>
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="add-varient"
                  onClick={() => {
                    clearErrors();
                    append({});
                  }}
                >
                  + Add Items
                </button>
              </div>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
