import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {
  City,
  Country,
  State,
  allCategory,
  allFoodType,
  allPincode,
  allTiffinCategory,
} from "../../../../utils/apis/masters/masters";
import { Context } from "../../../../utils/context";
import { Create } from "../../../../utils/apis/tiffin/tiffindishe";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [category, setCategory] = useState([]);
  const [foodtype, setFoodtype] = useState([]);

  const GetAllMasters = async () => {
    {
      const response = await allTiffinCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "tiffin_category_id"));
      }
    }

    {
      const response = await allFoodType();
      if (response?.success) {
        setFoodtype(await Select2Data(response?.data, "food_type_id"));
      }
    }
  };

  useEffect(() => {
    GetAllMasters();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      tiffin_category_id: "",
      food_type_id: "",
      name: "",
      time: "",
      price: "",
      packaging_charges: "",
      customize_spice: 0,
      free_delivery: 0,
      best_seller: 0,
      image: "",
      cover_image: "",
    },
  });

  const imageFile = watch("image");
  const cover_imageFile = watch("cover_image");

  const onSubmit = async (data) => {
    // console.log(data);
    // return;
    try {
      const finalData = new FormData();
      finalData.append("tiffin_category_id", data?.tiffin_category_id?.value);
      finalData.append("food_type_id", data?.food_type_id?.value);
      finalData.append("name", data?.name);
      finalData.append("time", data?.time);
      finalData.append("short_description", data?.short_description);
      // finalData.append("price", data?.price);
      // finalData.append("packaging_charges", data?.packaging_charges);
      // finalData.append("customize_spice", data?.customize_spice);
      // finalData.append("free_delivery", data?.free_delivery);
      // finalData.append("best_seller", data?.best_seller);
      // finalData.append("description", data?.description);
      // finalData.append("discount", data?.discount);
      finalData.append("vote", data?.vote);

      if (data?.image && data.image.length > 0) {
        finalData.append("image", data.image[0]);
      }

      if (data?.cover_image && data.cover_image.length > 0) {
        finalData.append("cover_image", data.cover_image[0]);
      }
      const response = await Create(finalData);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Tiffin Dishes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Category</Form.Label>
                    <Controller
                      name="tiffin_category_id"
                      {...register("tiffin_category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.tiffin_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={category}
                        />
                      )}
                    />
                    {errors.tiffin_category_id && (
                      <span className="text-danger">
                        {errors.tiffin_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Food Type</Form.Label>
                    <Controller
                      name="food_type_id"
                      {...register("food_type_id", {
                        required: "Select Food Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.food_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={foodtype}
                        />
                      )}
                    />
                    {errors.food_type_id && (
                      <span className="text-danger">
                        {errors.food_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Tiffin Dishes Name</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Tiffin Dishes Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Tiffin Dishes Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Time</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="time"
                          placeholder="Time"
                          className={classNames("", {
                            "is-invalid": errors?.time,
                          })}
                          {...register("time", {
                            required: "Time is required",
                          })}
                        />
                      </InputGroup>
                      {errors.time && (
                        <span className="text-danger">
                          {errors.time.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Short Description</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="short_description"
                          placeholder="Short Description"
                          className={classNames("", {
                            "is-invalid": errors?.short_description,
                          })}
                          {...register("short_description", {
                            required: "Short Description is required",
                          })}
                        />
                      </InputGroup>
                      {errors.short_description && (
                        <span className="text-danger">
                          {errors.short_description.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Tiffin Dishes Price</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="price"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Tiffin Dishes Price"
                          className={classNames("", {
                            "is-invalid": errors?.price,
                          })}
                          {...register("price", {
                            required: "Tiffin Dishes Price is required",
                          })}
                        />
                      </InputGroup>
                      {errors.price && (
                        <span className="text-danger">
                          {errors.price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Tiffin Dishes Packaging Charges</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="packaging_charges"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Tiffin Dishes Packaging Charges"
                          className={classNames("", {
                            "is-invalid": errors?.packaging_charges,
                          })}
                          {...register("packaging_charges", {})}
                        />
                      </InputGroup>
                      {errors.packaging_charges && (
                        <span className="text-danger">
                          {errors.packaging_charges.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Discount</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="discount"
                          placeholder={"Discount"}
                          max={100}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.discount,
                          })}
                          {...register("discount", {
                            // required: "Discount is required",
                            validate: (value) => {
                              // if (!value) return "Discount is required";
                              if (
                                value &&
                                (parseFloat(value) < 0 ||
                                  parseFloat(value) > 100)
                              ) {
                                return "Discount must be between 0% and 100%";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.discount && (
                        <span className="text-danger">
                          {errors.discount.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Vote</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="vote"
                          placeholder={"Vote"}
                          max={100}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.vote,
                          })}
                          {...register("vote", {
                            required: "Vote is required",
                            validate: (value) => {
                              if (!value) return "Vote is required";
                              if (
                                value &&
                                (parseFloat(value) < 0 || parseFloat(value) > 5)
                              ) {
                                return "Vote must be between 0 and 5.00";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.vote && (
                        <span className="text-danger">
                          {errors.vote.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Customize Spice</Form.Label>

                    <Form.Group>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          {...register("customize_spice", {})}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Free Delivery</Form.Label>

                    <Form.Group>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          {...register("free_delivery", {})}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Best Seller</Form.Label>

                    <Form.Group>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          {...register("best_seller", {})}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}></Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Cover Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.cover_image,
                          })}
                          type="file"
                          {...register("cover_image", {
                            required: "Cover Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.cover_image && (
                        <span className="text-danger">
                          {errors.cover_image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Cover Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {cover_imageFile && cover_imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(cover_imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Description
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="description" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Description is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.description,
                              })}
                              placeholder="Description"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col> */}

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
