import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import {
  City,
  Country,
  State,
  allCategory,
  allDaysType,
  allFoodType,
  allPincode,
  allTiffinCategory,
  allTiffinDishes,
  allWeekDays,
  allFeatures,
} from "../../../../utils/apis/masters/masters";
import { Context } from "../../../../utils/context";
import {
  Create,
  ImageUpload,
} from "../../../../utils/apis/Subscription/Subscription";
import JoditEditor from "jodit-react";
import { Label } from "recharts";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [category, setCategory] = useState([]);
  const [type, settype] = useState([
    {
      value: "Single",
      name: "type",
      label: "Single",
    },
    {
      value: "Subscription",
      name: "type",
      label: "Subscription",
    },
  ]);
  const [dayType, setdayType] = useState([]);
  const [Features, setFeatures] = useState([]);

  const GetAllMasters = async () => {
    {
      const response = await allTiffinCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "tiffin_category_id"));
      }
    }

    {
      const response = await allDaysType();
      if (response?.success) {
        setdayType(await Select2Data(response?.data, "day_type_id"));
      }
    }

    {
      const response = await allFeatures();
      if (response?.success) {
        setFeatures(await Select2Data(response?.data, "features_id"));
      }
    }
  };

  useEffect(() => {
    GetAllMasters();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      subscription_features: [],
    },
  });

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    // console.log(data);
    // console.log(data?.subscription_item?.length);
    // return;

    try {
      if (data.subscription_item.length) {
        clearErrors();

        const response = await Create(data);
        if (response?.success) {
          const formDataToSend = new FormData();
          if (data?.image && data.image.length > 0) {
            formDataToSend.append("image", data.image[0]);
          }
           await ImageUpload(response.data.id, formDataToSend);

          await setShowModal({
            code: response.code,
            message: response.message,
          });
        } else {
          await setShowModal({
            code: response.code,
            message: response.message,
          });
        }
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 1000);
      } else {
        setError("subscription_item", {
          type: "manual",
          message: "Add Subscription Items",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subscription_item",
  });

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Type</Form.Label>
                    <Controller
                      name="type"
                      {...register("type", {
                        required: "Select Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.type ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={type}
                        />
                      )}
                    />
                    {errors.type && (
                      <span className="text-danger">{errors.type.message}</span>
                    )}
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Day Type</Form.Label>
                    <Controller
                      name="day_type_id"
                      {...register("day_type_id", {
                        required: "Select Day Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.day_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={dayType}
                        />
                      )}
                    />
                    {errors.day_type_id && (
                      <span className="text-danger">
                        {errors.day_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Price"}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.price,
                          })}
                          name={`price`}
                          {...register(`price`, {
                            required: "Price is required",
                            validate: (value) => {
                              // if (!value) return "Discount is required";
                              if (value && parseFloat(value) < 0) {
                                return "Price is min 0 or greate";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                          // {...register(`subscription_item.${index}.tiffin_category_id`, {
                          //   required: "Discount is required",
                          //   validate: (value) => {
                          //     // if (!value) return "Discount is required";
                          //     // if (
                          //     //   value &&
                          //     //   (parseFloat(value) < 0 ||
                          //     //     parseFloat(value) > 100)
                          //     // ) {
                          //     //   return "Discount must be between 0% and 100%";
                          //     // }
                          //     return true; // Return true for valid input
                          //   },
                          // })}
                        />
                      </InputGroup>
                      {errors?.price && (
                        <span className="text-danger">
                          {errors?.price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Lunch Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Lunch Price"}
                          min={0}
                          className={classNames("", {
                            "is-invalid": errors?.lunch_price,
                          })}
                          name={`lunch_price`}
                          {...register(`lunch_price`, {
                            required: "Lunch Price is required",
                            validate: (value) => {
                              if (value && parseFloat(value) < 0) {
                                return "Lunch Price is min 0 or greate";
                              }
                              return true;
                            },
                          })}
                        />
                      </InputGroup>
                      {errors?.lunch_price && (
                        <span className="text-danger">
                          {errors?.lunch_price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Diner Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Diner Price"}
                          min={0}
                          // step="0.01"
                          className={classNames("", {
                            "is-invalid": errors?.diner_price,
                          })}
                          name={`diner_price`}
                          {...register(`diner_price`, {
                            required: "Diner Price is required",
                            validate: (value) => {
                              if (value && parseFloat(value) < 0) {
                                return "Diner Price is min 0 or greate";
                              }
                              return true;
                            },
                          })}
                        />
                      </InputGroup>
                      {errors?.diner_price && (
                        <span className="text-danger">
                          {errors?.diner_price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Both Price</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          placeholder={"Both Price"}
                          min={0}
                          className={classNames("", {
                            "is-invalid": errors?.both_price,
                          })}
                          name={`both_price`}
                          {...register(`both_price`, {
                            required: "Both Price is required",
                            validate: (value) => {
                              if (value && parseFloat(value) < 0) {
                                return "Both Price is min 0 or greate";
                              }
                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors?.both_price && (
                        <span className="text-danger">
                          {errors?.both_price.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={12}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Features</Form.Label>
                    <Controller
                      name="subscription_features"
                      {...register("subscription_features", {
                        required: "Select Features",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.subscription_features
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          isMulti
                          options={Features}
                        />
                      )}
                    />
                    {errors.subscription_features && (
                      <span className="text-danger">
                        {errors.subscription_features.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              {fields.map((variant, index) => (
                <div key={variant.id} className="main-form-section mt-3">
                  <Row>
                    <Col md={5}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tiffin Category</Form.Label>
                          <Controller
                            name={`subscription_item.${index}.tiffin_category_id`}
                            {...register(
                              `subscription_item.${index}.tiffin_category_id`,
                              {
                                required: "Select Tiffin Category",
                              }
                            )}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors?.subscription_item?.[
                                      index
                                    ]?.tiffin_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={category}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value); // Update Controller's value
                                  setValue(
                                    `subscription_item.${index}.tiffin_category_id`,
                                    selectedOption
                                  );
                                }}
                              />
                            )}
                          />
                          {errors?.subscription_item?.[index]
                            ?.tiffin_category_id && (
                            <span className="text-danger">
                              {
                                errors?.subscription_item?.[index]
                                  ?.tiffin_category_id.message
                              }
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={5}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>No</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                placeholder={"No"}
                                min={0}
                                // step="0.01"
                                className={classNames("", {
                                  "is-invalid":
                                    errors?.subscription_item?.[index]?.no,
                                })}
                                name={`subscription_item.${index}.no`}
                                {...register(`subscription_item.${index}.no`, {
                                  required: "No is required",
                                  validate: (value) => {
                                    // if (!value) return "Discount is required";
                                    if (value && parseFloat(value) < 0) {
                                      return "No is min 0 or greate";
                                    }
                                    return true; // Return true for valid input
                                  },
                                })}
                                // {...register(`subscription_item.${index}.tiffin_category_id`, {
                                //   required: "Discount is required",
                                //   validate: (value) => {
                                //     // if (!value) return "Discount is required";
                                //     // if (
                                //     //   value &&
                                //     //   (parseFloat(value) < 0 ||
                                //     //     parseFloat(value) > 100)
                                //     // ) {
                                //     //   return "Discount must be between 0% and 100%";
                                //     // }
                                //     return true; // Return true for valid input
                                //   },
                                // })}
                              />
                            </InputGroup>
                            {errors?.subscription_item?.[index]?.no && (
                              <span className="text-danger">
                                {errors?.subscription_item?.[index]?.no.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={2}>
                      <button
                        className="mt-3 add-varient"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        Remove Items
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}

              <div className="main-form-section mt-3"></div>
              <hr />
              {errors?.subscription_item && (
                <span className="text-danger">
                  {errors?.subscription_item.message}
                </span>
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="add-varient"
                  onClick={() => {
                    clearErrors();
                    append({});
                  }}
                >
                  + Add Items
                </button>
              </div>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
