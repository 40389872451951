import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/orders/return-orders/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderDetails = async (id) => {
  try {
    return await getData(`/orders/return-orders/all-orders-details/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const getRefundOrder = async (id) => {
  try {
    return await getData(`/orders/return-orders/refund-orders/${id}`);
  } catch (error) {
    console.error(error);
  }
};
