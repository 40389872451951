import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

import { add } from "../../../../utils/apis/masters/coupon_codes/coupon_codes";
import Datepicker from "../../common/Datepicker";
const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const imageFile = watch("image");
  const imageFile2 = watch("mobile_image");

  const onSubmit = async (data) => {
    // console.log("i am here");
    try {
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);
      DataToSend.append("code", data?.code);
      DataToSend.append("type", data?.type);
      if (data?.discount) {
        DataToSend.append("discount", data?.discount);
      }

      if (data?.total_amount) {
        DataToSend.append("total_amount", data?.total_amount);
      }
      DataToSend.append("coupon_message", data?.coupon_message);
      DataToSend.append("coupon_info", data?.coupon_info);
      DataToSend.append("minimum_order_value", data?.minimum_order_value);
      DataToSend.append("use_per_cupon", data?.use_per_cupon);
      DataToSend.append("use_per_customer", data?.use_per_customer);
      DataToSend.append("s_date", data?.s_date);
      DataToSend.append("e_date", data?.e_date);
      DataToSend.append(`image`, data?.image[0]);
      // DataToSend.append(`mobile_image`, data?.mobile_image[0]);

      const response = await add(DataToSend);
      // console.log("response", response);
      // console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
      setShowModal({
        code: "Error",
        message: "An error occurred. Please try again later.",
      });
    }
  };

  const handleStartDateChange = (e) => {
    setValue("s_date", e.target.value);
    // Assuming you want to clear the end date when start date changes
    setValue("e_date", "");
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Cuppon Code</Card.Title>
              <hr />
              <Container>
                <Form
                  // onSubmit={() => handleSubmit(onSubmit)}
                  role="form"
                  // className="stateclass"
                >
                  <Row>
                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Type</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                as="select"
                                name="type"
                                className={classNames("", {
                                  "is-invalid": errors?.type,
                                })}
                                {...register("type", {
                                  required: "Type is required",
                                })}
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  setValue("type", e.target.value);

                                  if (e.target.value == "percentage") {
                                    setValue("total_amount", null);
                                  } else if (e.target.value == "fixedamount") {
                                    setValue("discount", null);
                                  } else {
                                    setValue("total_amount", null);
                                    setValue("discount", null);
                                  }
                                  reset(getValues());
                                }}
                              >
                                <option value="">Select Type</option>
                                <option value="percentage">Percentage</option>
                                <option value="fixedamount">
                                  Fixed Amount
                                </option>
                                {/* <option value="combo">Combo</option> */}
                              </Form.Control>
                            </InputGroup>
                            {errors.type && (
                              <span className="text-danger">
                                {errors.type.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Name</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name .."
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                })}
                                {...register("name", {
                                  required: "Name is required",
                                })}
                              />
                            </InputGroup>
                            {errors.name && (
                              <span className="text-danger">
                                {errors.name.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Coupon Code </Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="code"
                                placeholder="Coupon Code .."
                                className={classNames("", {
                                  "is-invalid": errors?.code,
                                })}
                                {...register("code", {
                                  required: "Coupon Code is required",
                                })}
                              />
                            </InputGroup>
                            {errors.code && (
                              <span className="text-danger">
                                {errors.code.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    {/* <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Datepicker
                            reset={reset}
                            getValues={getValues}
                            setValue={setValue}
                            s_date={"s_date"}
                            e_date={"e_date"}
                          />
                        </Row>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Col>
                            <Form.Label>Start</Form.Label>
                            <input
                              type="date"
                              className={classNames("form-control", {
                                "is-invalid": errors?.s_date,
                              })}
                              {...register("s_date", {
                                required: "Date is required",
                              })}
                              onChange={(e) => {
                                setValue("s_date", e.target.value);
                                reset({
                                  ...getValues(),
                                  s_date: e.target.value,
                                });
                              }}
                            />
                            {errors.s_date && (
                              <div className="invalid-feedback">
                                {errors.s_date.message}
                              </div>
                            )}
                          </Col>
                          <Col>
                            <Form.Label>End</Form.Label>
                            <input
                              type="date"
                              className={classNames("form-control", {
                                "is-invalid": errors?.e_date,
                              })}
                              {...register("e_date", {
                                required: "Date is required",
                                validate: (value) =>
                                  !value ||
                                  new Date(value) >=
                                    new Date(getValues("s_date")) ||
                                  "End date cannot be before start date",
                              })}
                              min={getValues("s_date")}
                            />
                            {errors.e_date && (
                              <div className="invalid-feedback">
                                {errors.e_date.message}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col> */}

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="date"
                                name="s_date"
                                placeholder="Start Date"
                                className={classNames("", {
                                  "is-invalid": errors?.s_date,
                                })}
                                onChange={handleStartDateChange}
                                {...register("s_date", {
                                  required: "Start Date is required",
                                  validate: {
                                    notPast: (value) => {
                                      const today = new Date()
                                        .toISOString()
                                        .split("T")[0]; // Get today's date in YYYY-MM-DD format
                                      return (
                                        value >= today ||
                                        "Start Date cannot be in the past"
                                      );
                                    },
                                  },
                                })}
                              />
                            </InputGroup>
                            {errors.s_date && (
                              <span className="text-danger">
                                {errors.s_date.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>End Date</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="date"
                                name="e_date"
                                placeholder="End Date"
                                className={classNames("", {
                                  "is-invalid": errors?.e_date,
                                })}
                                min={getValues("s_date")}
                                {...register("e_date", {
                                  required: "End Date is required",
                                  validate: {
                                    notPast: (value) => {
                                      const startDate = new Date()
                                        .toISOString()
                                        .split("T")[0]; // Start date in YYYY-MM-DD format
                                      return (
                                        value >= startDate ||
                                        "End Date cannot be in the past of Start Date"
                                      );
                                    },
                                  },
                                })}
                              />
                            </InputGroup>
                            {errors.e_date && (
                              <span className="text-danger">
                                {errors.e_date.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    {getValues("type") == "percentage" && (
                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Discount</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="discount"
                                  placeholder="Discount"
                                  className={classNames("", {
                                    "is-invalid": errors?.discount,
                                  })}
                                  {...register("discount", {
                                    required: "Discount is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.discount && (
                                <span className="text-danger">
                                  {errors.discount.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                    )}

                    {getValues("type") == "fixedamount" && (
                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Total Amount</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="total_amount"
                                  placeholder="Total Amount"
                                  className={classNames("", {
                                    "is-invalid": errors?.total_amount,
                                  })}
                                  {...register("total_amount", {
                                    required: "Total Amount is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.total_amount && (
                                <span className="text-danger">
                                  {errors.total_amount.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                    )}

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Coupen Message</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="coupon_message"
                                placeholder="Coupen Message"
                                className={classNames("", {
                                  "is-invalid": errors?.coupon_message,
                                })}
                                {...register("coupon_message", {
                                  required: "Coupen Message is required",
                                })}
                              />
                            </InputGroup>
                            {errors.coupon_message && (
                              <span className="text-danger">
                                {errors.coupon_message.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Cuppon Info</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="coupon_info"
                                placeholder="Coupen Info"
                                className={classNames("", {
                                  "is-invalid": errors?.coupon_info,
                                })}
                                {...register("coupon_info", {
                                  required: "Coupen Info is required",
                                })}
                              />
                            </InputGroup>
                            {errors.coupon_info && (
                              <span className="text-danger">
                                {errors.coupon_info.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Minimum Order Value</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="minimum_order_value"
                                placeholder="Minimum Order Value"
                                className={classNames("", {
                                  "is-invalid": errors?.minimum_order_value,
                                })}
                                {...register("minimum_order_value", {
                                  required: "Minimum Order is required",
                                })}
                              />
                            </InputGroup>
                            {errors.minimum_order_value && (
                              <span className="text-danger">
                                {errors.minimum_order_value.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Use Per Coupon</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="use_per_cupon"
                                placeholder="Use Per Coupon"
                                className={classNames("", {
                                  "is-invalid": errors?.use_per_cupon,
                                })}
                                {...register("use_per_cupon", {
                                  required: "Use Per Coupon is required",
                                })}
                              />
                            </InputGroup>
                            {errors.use_per_cupon && (
                              <span className="text-danger">
                                {errors.use_per_cupon.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Use Per Customer</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="use_per_customer"
                                placeholder="Use Per Customer"
                                className={classNames("", {
                                  "is-invalid": errors?.use_per_customer,
                                })}
                                {...register("use_per_customer", {
                                  required: "Use Per Customer is required",
                                })}
                              />
                            </InputGroup>
                            {errors.use_per_customer && (
                              <span className="text-danger">
                                {errors.use_per_customer.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    {/* <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Use Per Customer</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="use_per_customer"
                                placeholder="Use Per Customer"
                                className={classNames("", {
                                  "is-invalid": errors?.use_per_customer,
                                })}
                                {...register("use_per_customer", {
                                  required: "Use Per Customer is required",
                                })}
                              />
                            </InputGroup>
                            {errors.use_per_customer && (
                              <span className="text-danger">
                                {errors.use_per_customer.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col> */}

                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Form.Label className="text-left">Image</Form.Label>

                          <Form.Group>
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.image,
                              })}
                              type="file"
                              {...register("image", {
                                required: "Image is required",
                              })}
                              accept=".jpg, .jpeg, .png"
                            />
                          </Form.Group>
                          {errors.image && (
                            <span className="text-danger">
                              {errors.image.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col className="main-form-section mt-3">
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label column sm={3} className="text-center">
                            Image Preview
                          </Form.Label>
                          <Col sm={9}>
                            {imageFile && imageFile?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  src={URL.createObjectURL(imageFile[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "150px", height: "130px" }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    {/* <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Form.Label className="text-left">
                            Mobile Image
                          </Form.Label>

                          <Form.Group>
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.mobile_image,
                              })}
                              type="file"
                              {...register("mobile_image", {
                                required: "Mobile Image is required",
                              })}
                              accept=".jpg, .jpeg, .png"
                            />
                          </Form.Group>
                          {errors.mobile_image && (
                            <span className="text-danger">
                              {errors.mobile_image.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col className="main-form-section mt-3">
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label column sm={3} className="text-center">
                            Image Preview
                          </Form.Label>
                          <Col sm={9}>
                            {imageFile2 && imageFile2?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  src={URL.createObjectURL(imageFile2[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "150px", height: "130px" }}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col> */}

                    <Row className="mt-5 pb-3">
                      <div className="d-flex justify-content-center">
                        <Link>
                          <CancelButton
                            name={"cancel"}
                            handleClose={props.handleClose}
                          />
                        </Link>

                        <Button
                          name={"save"}
                          onClick={handleSubmit(onSubmit)}
                          type="button"
                        >
                          save
                        </Button>
                      </div>
                    </Row>
                  </Row>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
