import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (
  page,
  per_page,
  search,
  status,
  week_day,
  day_type,
  tiffin_category
) => {
  try {
    let url = `/tiffin/tiffin-menu?`;
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    if (week_day) {
      url += `&week_day=${week_day}`;
    }
    if (day_type) {
      url += `&day_type=${day_type}`;
    }
    if (tiffin_category) {
      url += `&tiffin_category=${tiffin_category}`;
    }
    url += `&status=${status}`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Get = async (id) => {
  try {
    return await getData(`/tiffin/tiffin-menu/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/tiffin/tiffin-menu`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id, data) => {
  try {
    return await postData(`/tiffin/tiffin-menu/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const UpdateDiscount = async (id, data) => {
  try {
    return await postData(`/tiffin/tiffin-menu/discount/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Status = async (id) => {
  try {
    return await editStatusData(`/tiffin/tiffin-menu/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/tiffin/tiffin-menu/${id}`);
  } catch (error) {
    console.error(error);
  }
};
