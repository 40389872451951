import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (
  page,
  per_page,
  search,
  status,
  type,
  day_type
) => {
  try {
    let url = `/subscription/subscription?`;
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    if (type) {
      url += `&type=${type}`;
    }
    if (day_type) {
      url += `&day_type=${day_type}`;
    }

    url += `&status=${status}`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Get = async (id) => {
  try {
    return await getData(`/subscription/subscription/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/subscription/subscription`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id, data) => {
  try {
    return await postData(`/subscription/subscription/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const ImageUpload = async (id, data) => {
  try {
    return await postData(
      `/subscription/subscription/image-upload/${id}`,
      data
    );
  } catch (error) {
    console.error(error);
  }
};

export const Status = async (id) => {
  try {
    return await editStatusData(`/subscription/subscription/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/subscription/subscription/${id}`);
  } catch (error) {
    console.error(error);
  }
};
