import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllRequestedWallet = async (
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/wallet/active-wallet/requested?page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllAcceptedWallet = async (
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/wallet/active-wallet/accepted?page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllRejectedWallet = async (
  page = 1,
  search_name = "",
  startDate = "",
  endDate = ""
) => {
  try {
    return await getData(
      `/wallet/active-wallet/rejected?page=${page}&term=${search_name}&from=${startDate}&to=${endDate}`
    );
  } catch (error) {
    console.error(error);
  }
};

// export const getAllOrderDetails = async (id) => {
//   try {
//     return await getData(`/wallet/active-wallet/${id}`);
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getAcceptWallet = async (id) => {
  try {
    return await getData(`/wallet/active-wallet/accepted-requested/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const getRejectedWallet = async (id) => {
  try {
    return await getData(`/wallet/active-wallet/rejected-requested/${id}`);
  } catch (error) {
    console.error(error);
  }
};
