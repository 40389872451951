import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Country = async () => {
  try {
    return await getData(`/without-login/masters/all-country`);
  } catch (error) {
    console.error(error);
  }
};

export const State = async (id) => {
  // console.log(id);
  try {
    return await getData(`/without-login/masters/all-states/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/masters/all-city/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Pincode = async (name) => {
  try {
    return await getData(`/without-login/masters/all-pincode/${name}`);
  } catch (error) {
    console.error(error);
  }
};

export const allPincode = async (id) => {
  try {
    return await getData(`/without-login/masters/all-pincode/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const allCategory = async () => {
  try {
    return await getData(`/without-login/masters/all-category`);
  } catch (error) {
    console.error(error);
  }
};

export const allResturentCategory = async () => {
  try {
    return await getData(`/without-login/masters/all-category`);
  } catch (error) {
    console.error(error);
  }
};

export const allFoodType = async () => {
  try {
    return await getData(`/without-login/masters/all-food-type`);
  } catch (error) {
    console.error(error);
  }
};

export const allTiffinCategory = async () => {
  try {
    return await getData(`/without-login/masters/all-tiffin-category`);
  } catch (error) {
    console.error(error);
  }
};

export const allTiffinDishes = async (id) => {
  try {
    return await getData(`/without-login/masters/all-tiffin-dishes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const allFeatures = async () => {
  try {
    return await getData(`/without-login/masters/all-features`);
  } catch (error) {
    console.error(error);
  }
};

export const allWeekDays = async () => {
  try {
    return await getData(`/without-login/masters/all-week-days`);
  } catch (error) {
    console.error(error);
  }
};

export const allDaysType = async () => {
  try {
    return await getData(`/without-login/masters/all-day-type`);
  } catch (error) {
    console.error(error);
  }
};

export const allDishes = async (id) => {
  try {
    return await getData(`/without-login/masters/all-dishes/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const DeliveryBoys = async () => {
  try {
    return await getData(`/without-login/masters/all-delivery-boy`);
  } catch (error) {
    console.error(error);
  }
};

export const getd_boy_order_status = async () => {
  try {
    return await getData(`/without-login/masters/all-d-boy-order-status`);
  } catch (error) {
    console.error(error);
  }
};

export const allDishesForBanner = async () => {
  try {
    return await getData(`/without-login/masters/all-dishes-for-banner`);
  } catch (error) {
    console.error(error);
  }
};
