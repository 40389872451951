import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllOrders = async (
  seller_id,
  page = 1,
  search_name = "",
  startDate = "",
  endDate = "",
  delivery_boy_id = "",
  order_status_id = ""
) => {
  try {
    // delivery_boy_id: delivery_boy_id?.value,
    return await getData(
      `/orders-assigned/orders-assigned/all-orders?seller_id=${seller_id}&page=${page}&term=${search_name}&from=${startDate}&to=${endDate}&delivery_boy_id=${delivery_boy_id}&order_status_id=${order_status_id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderDetails = async (id) => {
  try {
    return await getData(
      `/orders-assigned/orders-assigned/all-orders-details/${id}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAllOrderStatus = async () => {
  try {
    return await getData(`/orders-assigned/orders-assigned/all-order-status`);
  } catch (error) {
    console.error(error);
  }
};

export const postAllOrderStatus = async (data, order) => {
  try {
    return await postData(`/orders-assigned/orders-assigned/all-order-status`, {
      ids: data,
      order: order?.value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const postAllDeliveryBoyAssign = async (data, delivery_boy_id) => {
  try {
    return await postData(
      `/orders-assigned/orders-assigned/all-assigne-orders`,
      {
        ids: data,
        delivery_boy_id: delivery_boy_id?.value,
      }
    );
  } catch (error) {
    console.error(error);
  }
};
