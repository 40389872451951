import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {
  City,
  Country,
  State,
  allCategory,
  allPincode,
} from "../../../../utils/apis/masters/masters";
import { Context } from "../../../../utils/context";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [workinTypes, setWorkingTypes] = useState([]);
  const [category, setCategory] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [country, setCountry] = useState([]);

  const GetAllCountry = async () => {
    const response = await Country();

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }

    {
      const response = await allCategory();

      if (response?.success) {
        setCategory(await Select2Data(response?.data, "category_id"));
      }
    }
  };

  const [state, setState] = useState([]);
  const GetAllState = async (id) => {
    const response = await State(id);

    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const [city, setCity] = useState([]);
  const GetAllCity = async (id) => {
    const response = await City(id);

    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  const [pincode, setPincode] = useState([]);
  const GetAllPincode = async (id) => {
    const response = await allPincode(id);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    // GetAllState();
    // GetAllCity();
    // GetAllPincode();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    // console.log(data);
    // return;
    try {
      const finalData = new FormData();

      finalData.append("country_id", data?.country_id?.value);
      finalData.append("state_id", data?.state_id?.value);
      finalData.append("city_id", data?.city_id?.value);
      finalData.append("pincode_id", data?.pincode_id?.value);
      finalData.append("name", data?.name);
      finalData.append("latitude", data?.latitude);
      finalData.append("longitude", data?.longitude);
      finalData.append("email", data?.email);
      finalData.append("category_id", JSON.stringify(data?.category_id));
      finalData.append("password", data?.password);
      finalData.append("address", data?.address);
      finalData.append("landmark", data?.landmark);
      if (data?.image && data.image.length > 0) {
        finalData.append("image", data.image[0]);
      }
      const response = await postData(`/masters/restaurent`, finalData);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Restaurent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country_id"
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            GetAllState(selectedOption.value);
                            setValue("country_id", selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state_id"
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={state}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            GetAllCity(selectedOption.value);
                            setValue("state_id", selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city_id"
                      {...register("city_id", {
                        required: "Select City",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={city}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            GetAllPincode(selectedOption.value);
                            setValue("city_id", selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.city_id && (
                      <span className="text-danger">
                        {errors.city_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Pincode</Form.Label>
                    <Controller
                      name="pincode_id"
                      {...register("pincode_id", {
                        required: "Select Pincode",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincode}
                        />
                      )}
                    />
                    {errors.pincode_id && (
                      <span className="text-danger">
                        {errors.pincode_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Restaurent Email</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="email"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Restaurent Email"
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Restaurent Email is required",
                          })}
                        />
                      </InputGroup>
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Restaurent Password</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="password"
                          name="password"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Restaurent Password"
                          className={classNames("", {
                            "is-invalid": errors?.password,
                          })}
                          {...register("password", {
                            required: "Restaurent Password is required",
                          })}
                        />
                      </InputGroup>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Restaurent Name</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Restaurent Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Restaurent Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Landmark</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="landmark"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Landmark"
                          className={classNames("", {
                            "is-invalid": errors?.landmark,
                          })}
                          {...register("landmark", {
                            required: "Landmark is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.landmark && (
                        <span className="text-danger">
                          {errors.landmark.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Address</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="landmark"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="address"
                          className={classNames("", {
                            "is-invalid": errors?.address,
                          })}
                          {...register("address", {
                            required: "Address is required",
                          })}
                          // id="inputEmail3"
                          // className="input-mandatory"
                        />
                      </InputGroup>
                      {errors.address && (
                        <span className="text-danger">
                          {errors.address.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Category</Form.Label>
                    <Controller
                      name="category_id"
                      {...register("category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          isMulti
                          {...field}
                          options={category}
                        />
                      )}
                    />
                    {errors.category_id && (
                      <span className="text-danger">
                        {errors.category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Latitude</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="latitude"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Latitude"
                          className={classNames("", {
                            "is-invalid": errors?.latitude,
                          })}
                          {...register("latitude", {
                            required: "Latitude is required",
                          })}
                        />
                      </InputGroup>
                      {errors.latitude && (
                        <span className="text-danger">
                          {errors.latitude.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Longitude</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="longitude"
                          // value={formData.name}
                          // onChange={handleChange}
                          placeholder="Longitude"
                          className={classNames("", {
                            "is-invalid": errors?.longitude,
                          })}
                          {...register("longitude", {
                            required: "Longitude is required",
                          })}
                        />
                      </InputGroup>
                      {errors.longitude && (
                        <span className="text-danger">
                          {errors.longitude.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image</Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.image,
                          })}
                          type="file"
                          {...register("image", {
                            required: "Image is required",
                          })}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        />
                      </Form.Group>
                      {errors.image && (
                        <span className="text-danger">
                          {errors.image.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Image Preview
                    </Form.Label>
                    <Col sm={9}>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            src={URL.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "150px", height: "130px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
