import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search, status) => {
  try {
    let url = `/masters/cancellation-policy?`;
    if (page) {
      url += `&page=${page}`;
    }

    if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    url += `&status=${status}`;

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Get = async (id) => {
  try {
    return await getData(`/masters/cancellation-policy/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/masters/cancellation-policy`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id, data) => {
  try {
    return await postData(`/masters/cancellation-policy/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Status = async (id) => {
  try {
    return await editStatusData(`/masters/cancellation-policy/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/masters/cancellation-policy/${id}`);
  } catch (error) {
    console.error(error);
  }
};
